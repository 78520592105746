.contact {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  padding: 3vh 2vw;
  color: var(--lightgray);
}

.contact h3 {
  font-size: 1.7em;
  margin: 8vh 0 10vh 0;
}

.contact h4 {
  font-size: 1.1em;
  margin-bottom: 2vh;
}

.contact-links {
  display: flex;
  justify-content: space-evenly;
}

.contact-links a {
  margin: 10px 15px;
}

.contact-links img {
  width: 8vw;
}
/* #linkedin {
  width: 7vw;
} */
#email-link {
  width: 9vw;
}