
.projects {
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
}


.projects-window {
  height: 70vh;
  width: 60vw;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  border-radius: 4%;
}

.projects-list {
  display: flex;
  flex-direction: column;
  height: 70vh;
  width: 30vw;
  justify-content: flex-start;
  padding: 5%;
  
}



.projects-list button {
  margin: 10% 0;
  background: none;
  border: none;
  color: var(--lightgray);
  text-align: left;
  font-size: 1.2em;
} 

#projects-header {
  color: var(--lightblue2);
  margin-bottom: 4vh;
  font-size: 1.75em;
}

@media screen and (min-width: 768px) {
  .projects-list button:hover {
    font-weight: 800;
  }
}