@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.about {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
}

#profile-pic {
  margin: 2vh 0 0 0;
  width: 23vw;
  border-radius: 10%;
}

.about-text {
  display: flex;
  flex-direction: column;
  width: 52vw;
  align-items: center;
  text-align: justify;
  color: var(--lightgray);
  font-size: 1.2em;
  font-weight: 500;
}
.about-text a {
  margin-top: 5%;
  color: var(--lightblue2)
}

.skills-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 85vw;
  padding: 2vh 0;
}
.skill-icon {
  width: 4vw;
  margin: 0 6px;
}



.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 5% 5%;
  box-sizing: border-box;
  scroll-snap-align: start;
  background-color: var(--lightgray);
}

.project-links {
  display: flex;
  justify-content: space-around;
}

.project-links button {
  background-color: var(--darkblue2);
  border: none;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2%;
  border-radius: 4%;
  white-space: nowrap;
  color: white;
}

.project-image {
  max-width: 25vw;
  margin: 1vh 2vw 2vh 2vw;
}

.description {
  margin: 10px;
  padding: 0 8vw;
  text-align: justify;
}

.project-links * {
  margin: 15px;
}

.projects {
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
}


.projects-window {
  height: 70vh;
  width: 60vw;
  overflow-y: scroll;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  border-radius: 4%;
}

.projects-list {
  display: flex;
  flex-direction: column;
  height: 70vh;
  width: 30vw;
  justify-content: flex-start;
  padding: 5%;
  
}



.projects-list button {
  margin: 10% 0;
  background: none;
  border: none;
  color: var(--lightgray);
  text-align: left;
  font-size: 1.2em;
} 

#projects-header {
  color: var(--lightblue2);
  margin-bottom: 4vh;
  font-size: 1.75em;
}

@media screen and (min-width: 768px) {
  .projects-list button:hover {
    font-weight: 800;
  }
}
.contact {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  padding: 3vh 2vw;
  color: var(--lightgray);
}

.contact h3 {
  font-size: 1.7em;
  margin: 8vh 0 10vh 0;
}

.contact h4 {
  font-size: 1.1em;
  margin-bottom: 2vh;
}

.contact-links {
  display: flex;
  justify-content: space-evenly;
}

.contact-links a {
  margin: 10px 15px;
}

.contact-links img {
  width: 8vw;
}
/* #linkedin {
  width: 7vw;
} */
#email-link {
  width: 9vw;
}
.home {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}


.home-header {
  height: 50vh;
  color: var(--lightgray);
  padding: 10vh 10vw;
  font-family: 'Noto Sans JP', sans-serif;  
  font-size: 1.6em;
  text-align: center;
}
.home-header h4 {
  font-size: 1.1em;
  margin-top: 1vh;
}

.home-header * {
  -webkit-animation: landing-fade-in;
          animation: landing-fade-in;
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes landing-fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes landing-fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.links  {
  padding: 0 12vw;
  height: 50vh;
  width: 100vw;
  display: flex;
  justify-content: space-around;
}

.about-link {
  opacity: 0;
  height: 10vh;
  width: 18vw;
  border-radius: 8px;
  padding: 4%;
  background-color: var(--darkblue2);
  color: var(--lightgray);
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  -webkit-animation: fade-in2;
          animation: fade-in2;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}


.projects-link {
  opacity: 0;
  height: 10vh;
  width: 18vw;
  border-radius: 8px;
  padding: 4%;
  background-color: var(--lightblue2);
  color: var(--lightgray);
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  -webkit-animation: fade-in2;
          animation: fade-in2;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.contact-link {
  opacity: 0;

  height: 10vh;
  width: 18vw;
  border-radius: 8px;
  padding: 4%;
  background-color: var(--lightgray);
  color: var(--darkblue2);
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  -webkit-animation: fade-in2;
          animation: fade-in2;
  -webkit-animation-delay: 3.5s;
          animation-delay: 3.5s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes fade-in2 {
  0% {opacity: 0}
  100% {opacity: 1}
}

@keyframes fade-in2 {
  0% {opacity: 0}
  100% {opacity: 1}
}



body * {
  box-sizing: border-box;
}

:root {
  --red: #870616;
  --blue: #00afff;
  --gray: #667899;
  --lightgray: #dff1ff;

  --red2: #aa1300;
  --darkblue2: #0069bf;
  --lightblue2: #009df8;

}


body {
  background-color: #333;
  font-family: 'Noto Sans JP', sans-serif;  
}

h1, h2, h3, h4 {
  font-weight: normal;
  margin: 0;
}

body a {
text-decoration: none;
color: inherit;
}

.noscroll {
  overflow-y: hidden;

}

.top-button {
  background-color: #0069bf;
  background-color: var(--darkblue2);
  color: #dff1ff;
  color: var(--lightgray);
  border: none;
  border-radius: 8%;
  height: 4vh;
  width: 5vw;
  font-size: 1.1em;
  font-weight: bold;
  margin-top: 4vh;
}


