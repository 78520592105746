
.about {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
}

#profile-pic {
  margin: 2vh 0 0 0;
  width: 23vw;
  border-radius: 10%;
}

.about-text {
  display: flex;
  flex-direction: column;
  width: 52vw;
  align-items: center;
  text-align: justify;
  color: var(--lightgray);
  font-size: 1.2em;
  font-weight: 500;
}
.about-text a {
  margin-top: 5%;
  color: var(--lightblue2)
}

.skills-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 85vw;
  padding: 2vh 0;
}
.skill-icon {
  width: 4vw;
  margin: 0 6px;
}

