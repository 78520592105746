@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');

.home {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}


.home-header {
  height: 50vh;
  color: var(--lightgray);
  padding: 10vh 10vw;
  font-family: 'Noto Sans JP', sans-serif;  
  font-size: 1.6em;
  text-align: center;
}
.home-header h4 {
  font-size: 1.1em;
  margin-top: 1vh;
}

.home-header * {
  animation: landing-fade-in;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}

@keyframes landing-fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.links  {
  padding: 0 12vw;
  height: 50vh;
  width: 100vw;
  display: flex;
  justify-content: space-around;
}

.about-link {
  opacity: 0;
  height: 10vh;
  width: 18vw;
  border-radius: 8px;
  padding: 4%;
  background-color: var(--darkblue2);
  color: var(--lightgray);
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  animation: fade-in2;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}


.projects-link {
  opacity: 0;
  height: 10vh;
  width: 18vw;
  border-radius: 8px;
  padding: 4%;
  background-color: var(--lightblue2);
  color: var(--lightgray);
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  animation: fade-in2;
  animation-delay: 2.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.contact-link {
  opacity: 0;

  height: 10vh;
  width: 18vw;
  border-radius: 8px;
  padding: 4%;
  background-color: var(--lightgray);
  color: var(--darkblue2);
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  animation: fade-in2;
  animation-delay: 3.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fade-in2 {
  0% {opacity: 0}
  100% {opacity: 1}
}


