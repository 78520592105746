
.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 5% 5%;
  box-sizing: border-box;
  scroll-snap-align: start;
  background-color: var(--lightgray);
}

.project-links {
  display: flex;
  justify-content: space-around;
}

.project-links button {
  background-color: var(--darkblue2);
  border: none;
  height: fit-content;
  width: fit-content;
  padding: 2%;
  border-radius: 4%;
  white-space: nowrap;
  color: white;
}

.project-image {
  max-width: 25vw;
  margin: 1vh 2vw 2vh 2vw;
}

.description {
  margin: 10px;
  padding: 0 8vw;
  text-align: justify;
}

.project-links * {
  margin: 15px;
}